<template>
  <div class="auth-screen driver-signup-main dstart-app-main">
    <div class="container">
      <div class="row">
        <div class="col-md-12">
          <div class="auth-fold">
            <div class="auth-body">
              <div class="auth-left">
                <figure>
                   <img :src="require('@assets/images/white-logo.svg')" alt="logo">
                </figure>
                <h2>Download driver app to get started</h2>
                <div class="driver-access-app">
                  <a :href="playStoreURL" target="_blank">
                     <img :src="require('@assets/images/Playstore.png')" alt="">
                  </a>
                  <a :href="appleStoreURL" target="_blank">
                     <img :src="require('@assets/images/Appstore.png')" alt="">
                  </a>
                </div>
              </div>
              <div class="auth-right">
                <figure>
                   <img :src="require('@assets/images/driver-signup-main.svg')" alt="">
                </figure>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'driver-signup-done',
  data() {
    return {
      playStoreURL: process.env.VUE_APP_PLAY_STORE_URL,
      appleStoreURL: process.env.VUE_APP_APPLE_STORE_URL,
    };
  },
};
</script>
